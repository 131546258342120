import React from 'react';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import {
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  FieldName,
  FieldPath,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import Select, {
  GroupBase,
  InputProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  components,
  PlaceholderProps,
} from 'react-select';

import HeroIcon from '@components/atoms/hero-icon';

function Input<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: InputProps<Option, IsMulti, Group>,
) {
  return <components.Input {...props} inputClassName="focus:ring-transparent" />;
}

function MultiValueContainer<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueGenericProps<Option, IsMulti, Group>) {
  return (
    <components.MultiValueContainer
      {...props}
      innerProps={{
        ...props.innerProps,
      }}
    />
  );
}

function MultiValueLabel<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: MultiValueGenericProps<Option, IsMulti, Group>,
) {
  return <components.MultiValueLabel {...props} innerProps={{ ...props.innerProps, className: 'text-white p-px' }} />;
}

function Placeholder<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: PlaceholderProps<Option, IsMulti, Group>,
) {
  return <components.Placeholder {...props} innerProps={{ ...props.innerProps, className: 'text-paragraph-medium' }} />;
}

function MultiValueRemove<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>,
) {
  return (
    <components.MultiValueRemove {...props} innerProps={{ ...props.innerProps, className: 'text-white' }}>
      <HeroIcon icon="XCircleIcon" extraClassNames="w-5 h-5" />
    </components.MultiValueRemove>
  );
}

export interface MultiSelectFormFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  label: string;
  id: string;
  inputOptions: { label: string; value: string }[];
  placeholder?: string;
  options?: RegisterOptions;
  isLoading?: boolean | undefined;
  onMenuScrollToBottom?: () => void;
}
export function MultiSelectFormField<T extends FieldValues>({
  field,
  label,
  id,
  inputOptions,
  placeholder,
  options,
  isLoading,
  onMenuScrollToBottom,
}: MultiSelectFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();

  return (
    <div className="overflow-visible">
      <label className="text-paragraph-medium">
        {label}
        {options?.required && <span className="text-danger-60">*</span>}
      </label>
      <Controller
        control={control}
        name={field}
        rules={options}
        render={({ field: { ref, name, onChange, onBlur, value } }) => {
          const optionsValues = inputOptions.map((option) => option.value);
          const translatedValue: typeof inputOptions = [];
          (value as string[])?.forEach((v) => {
            const indexOfOption = optionsValues.indexOf(`${v}`);
            if (indexOfOption > -1) translatedValue.push(inputOptions[indexOfOption]);
          });

          return (
            <Select<{ label: string; value: string }, true>
              options={inputOptions}
              isMulti={true}
              inputId={id}
              ref={ref}
              isLoading={isLoading}
              value={translatedValue}
              onBlur={onBlur}
              className="z-50 rounded-small text-paragraph-medium"
              components={{ Input, MultiValueContainer, MultiValueLabel, MultiValueRemove, Placeholder }}
              placeholder={placeholder}
              name={name}
              onInputChange={(inputValue) => options?.onChange && options?.onChange(inputValue)}
              onChange={(newValue) => {
                onChange(newValue.map((selected) => selected.value));
              }}
              menuPortalTarget={document.body}
              onMenuScrollToBottom={onMenuScrollToBottom}
              styles={{
                valueContainer: (base, state) => ({
                  ...base,
                  overflow: `${state.hasValue ? 'auto' : 'hidden'}`,
                  maxHeight: '1.5rem',
                  height: '1.5rem',
                  minHeight: '1.5rem',
                  marginBottom: '0.5rem',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  padding: 'None',
                  margin: 'None',
                }),
                multiValue: (base) => ({
                  ...base,
                  padding: 'None',
                  magin: 'None',
                }),
                control: (base) => ({
                  ...base,
                  '&:hover': { borderColor: errors[field]?.message ? 'red' : '#C5C5C5' },
                  border: `1px solid ${errors[field]?.message ? 'red' : ' #C5C5C5'}`,
                  boxShadow: 'none',
                  maxHeight: 32,
                  height: 32,
                  minHeight: 32,
                }),
                menuPortal: (defaultStyles) => ({
                  ...defaultStyles,
                  zIndex: 9999,
                }),
              }}
            />
          );
        }}
      />
      <ErrorMessage
        name={field as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrorsImpl<DeepRequired<T>>>>}
        errors={errors}
        render={({ message }) => (
          <span className="block pr-10 w-full rounded-md focus:outline-none text-danger-60 border-danger-30 sm:text-paragraph-medium focus:border-danger-30 focus:ring-danger-60">
            {message}
          </span>
        )}
      />
    </div>
  );
}
