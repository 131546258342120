import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { Checkbox, Tooltip } from '@clarke-energia/foton';

export interface IGeneralFormSelectableInputsOptions {
  defaultChecked?: boolean;
  id: string;
  optionLabel: string;
  value: string;
}

export interface CheckboxFormFieldContractProps<T extends FieldValues> {
  field: FieldPath<T>;
  inputOptions: Array<IGeneralFormSelectableInputsOptions>;
  label: string;
  options?: RegisterOptions;
  tooltipContent?: string;
}

export function CheckboxFormFieldContract<T extends FieldValues>({
  field,
  inputOptions,
  label,
  options,
  tooltipContent,
}: CheckboxFormFieldContractProps<T>) {
  const { control } = useFormContext<T>();
  return (
    <div className="flex flex-col pt-4 space-y-3">
      <div className="flex gap-2 mb-2">
        <label className="text-paragraph-medium">
          {label}
          {options?.required && label && <span className="text-danger-60">*</span>}
        </label>
        {tooltipContent && <Tooltip content={tooltipContent} />}
      </div>
      <div className="flex flex-wrap gap-6">
        {inputOptions.map(({ id, value, optionLabel, defaultChecked }, _) => (
          <Controller
            key={id}
            control={control}
            name={field}
            rules={options}
            render={({ field: { name, onBlur, onChange } }) => (
              <Checkbox
                id={id}
                name={name}
                label={optionLabel}
                value={value}
                checked={defaultChecked}
                defaultChecked={defaultChecked}
                required={(options?.required as boolean) || false}
                onBlur={onBlur}
                onChange={options?.onChange ?? onChange}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
}
