import React from 'react';
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import Select from 'react-select';

export interface ComboBoxFormFieldProps<T extends FieldValues> {
  id: string;
  field: FieldPath<T>;
  label: string;
  placeholder?: string;
  maxMenuHeightInPx?: number;
  inputOptions: { label: string; value: string | number }[];
  options?: RegisterOptions;
  transform?: {
    input?: (value: number | string) => number | string;
    output?: (value: number | string) => number | string;
  };
  onInputChange?: (newValue?: string) => void;
  isLoading?: boolean;
  onMenuScrollToBottom?: () => void;
}

export function ComboBoxFormField<T extends FieldValues>({
  field,
  id,
  inputOptions,
  placeholder,
  options,
  label,
  maxMenuHeightInPx,
  onInputChange,
  onMenuScrollToBottom,
  isLoading,
}: ComboBoxFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();
  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value, ref } }) => (
        <div>
          <label className="text-paragraph-medium">
            {label}
            {options?.required && <span className="text-danger-60">*</span>}
          </label>
          <Select
            ref={ref}
            id={id}
            isLoading={isLoading}
            name={name}
            options={inputOptions as []}
            value={inputOptions.find((val) => val.value === value) || null}
            placeholder={placeholder || 'Selecione uma opção'}
            onBlur={onBlur}
            onChange={(option: any) => {
              if (option) onChange(option.value);
            }}
            onMenuScrollToBottom={onMenuScrollToBottom}
            onInputChange={onInputChange}
            maxMenuHeight={maxMenuHeightInPx}
            styles={{
              control: (base) => ({
                ...base,
                '&:hover': { borderColor: errors[field]?.message ? 'red' : '#C5C5C5' },
                border: `1px solid ${errors[field]?.message ? 'red' : ' #C5C5C5'}`,
                boxShadow: 'none',
                height: 33,
                minHeight: 33,
                padding: 'none',
              }),
              input: (base) => ({
                ...base,
                marginBottom: 2,
                height: '100%',
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#00DF7C' : 'none',
                '&:hover': { backgroundColor: state.isSelected ? 'none' : '#F1FFEB' },
              }),
            }}
            data-cy={id}
          />
          <div className="text-paragraph-small text-danger-60">{errors[field]?.message as string | undefined}</div>
        </div>
      )}
    />
  );
}
