import React from 'react';
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { RadioButton } from '@clarke-energia/foton';

export interface RadioFormFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  label: string;
  inputOptions: { id: string; optionLabel: string; value: string; defaultChecked?: boolean }[];
  options?: RegisterOptions;
  valueAsBoolean?: boolean;
}

export function RadioFormField<T extends FieldValues>({
  field,
  label,
  inputOptions,
  options,
  valueAsBoolean,
}: RadioFormFieldProps<T>) {
  const { control, setValue } = useFormContext<T>();
  return (
    <div className="flex flex-col space-y-3">
      <label className="text-paragraph-medium">
        {label}
        {options?.required && <span className="text-danger-60">*</span>}
      </label>
      {inputOptions.map(({ id, value, optionLabel, defaultChecked }, index) => (
        <Controller
          key={id}
          control={control}
          name={field}
          rules={options}
          render={({ field: { name, onBlur } }) => (
            <RadioButton
              id={id}
              name={name}
              label={optionLabel}
              value={value}
              defaultChecked={defaultChecked}
              required={(options?.required as boolean) || false}
              onBlur={onBlur}
              onChange={(e) =>
                // @ts-expect-error -> dynamic type of field
                setValue(field, valueAsBoolean ? (e.target.value == 'true' ? true : false) : e.target.value)
              }
            />
          )}
        />
      ))}
    </div>
  );
}
