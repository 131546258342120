import { gql } from '@apollo/client';

export const GET_ECONOMY_REPORT_BY_ID_QUERY = gql`
  query GetEconomyReport($economyReportId: ID!) {
    economyReport(id: $economyReportId) {
      id
      date
      devecDeclaration
      energyTotalConsumption
      currentSavings
      energyTotalCost
      savingsPercent
      trader {
        name
      }
      unit {
        id
        name
        tariffSubgroup
        tariffModality
        contractedDemandOffPeak
        contractedDemandPeak
        group {
          name
        }
        edc {
          name
          state
        }
      }
      freeMarket {
        totalValue
        totalValueWithoutTax
        energyDistribution {
          acrBilledDemandOffPeakAmount
          acrBilledDemandOffPeakCost
          acrBilledDemandOffPeakTotal
          acrBilledDemandPeakAmount
          acrBilledDemandPeakCost
          acrBilledDemandPeakTotal
          acrTeOffPeakAmount
          acrTeOffPeakCost
          acrTeOffPeakTotal
          acrTePeakAmount
          acrTePeakCost
          acrTePeakTotal
          billedDemandOffPeakAmount
          billedDemandOffPeakCost
          billedDemandOffPeakTotal
          billedDemandPeakAmount
          billedDemandPeakCost
          billedDemandPeakTotal
          tusdPeakAmount
          tusdPeakCost
          tusdPeakTotal
          tusdOffPeakAmount
          tusdOffPeakCost
          tusdOffPeakTotal
          covidTariffAmount
          covidTariffCost
          covidTariffTotal
          overtakingAmount
          overtakingCost
          overtakingTotal
          reactivesTotal
          taxesTotal
          others
          totalValue
        }
        energyProvided {
          energyProvidedAmount
          energyProvidedCost
          energyProvidedTotal
          energyProvidedAdjustmentValue
          highlightPisCofinsCost
          highlightPisCofinsTotal
          icmsCost
          icmsTotal
          totalValue
        }
        others {
          clarkeManagementCost
          totalValue
          cceeCost
          eerCost
          essCost
        }
      }
      conventionalMarket {
        billedDemandOffPeakAmount
        billedDemandOffPeakCost
        billedDemandOffPeakTotal
        billedDemandPeakAmount
        billedDemandPeakCost
        billedDemandPeakTotal
        tusdPeakAmount
        tusdPeakCost
        tusdPeakTotal
        tusdOffPeakAmount
        tusdOffPeakCost
        tusdOffPeakTotal
        tePeakAmount
        tePeakCost
        tePeakTotal
        teOffPeakAmount
        teOffPeakCost
        teOffPeakTotal
        overtakingAmount
        overtakingCost
        overtakingTotal
        reactivesTotal
        tariffFlagCost
        tariffFlagTotal
        taxesTotal
        others
        totalValueWithoutTax
        totalValue
      }
    }
  }
`;
