import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Button, Modal } from '@clarke-energia/foton';

import { formatDateFromString } from '@utils/dayjs';
import { EconomyNotificationFeedbackContents } from '@utils/constants/common';

import {
  EconomyReportPayload,
  EMPTY_REPORT,
  GroupEconomyReportPayload,
  TariffModality,
} from '@contexts/economy-reports/types';
import TradersProvider from '@contexts/trader';
import useGroupEconomyReports from '@hooks/use-economy-report';
import useEconomyReportForm from '@hooks/use-economy-report-form';

import SimulationAcrInputs from '@components/molecules/form/form-upsert-economy-report/form-sections/acr-inputs';
import CustomerInputs from '@components/molecules/form/form-upsert-economy-report/form-sections/customer-inputs';
import DistributionAclInputs from '@components/molecules/form/form-upsert-economy-report/form-sections/distribution-acl-inputs';
import ProvidedAclInputs from '@components/molecules/form/form-upsert-economy-report/form-sections/provided-acl-inputs';
import { filterUnit } from '@components/molecules/form/form-upsert-economy-report/helpers';
import FormConfirmation from '../form-confirmation';
import { calcFinancialAmountsFromUnitsConsumption } from '@contexts/economy-reports/parser';

interface EconomyReportFormProps {
  initDataForm: EconomyReportPayload | GroupEconomyReportPayload | null;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  shouldResetForm: boolean;
  onFormSuccess: () => void;
  onFormFail: () => void;
  closeFormAction: () => void;
}

const EconomyReportForm = ({
  initDataForm,
  shouldResetForm,
  setShowForm,
  onFormSuccess,
  onFormFail,
  closeFormAction,
}: EconomyReportFormProps) => {
  const {
    getEnergyAuditsByGroupIdHandler,
    getAllFinancialEventsByGroupIdHandler,
    getSupplyInvoiceHandler,
    unitsConsumptionFinancialEvent,
    supplySection,
  } = useGroupEconomyReports();
  const { groupReportFormData, upsertEconomyReportHandler } = useEconomyReportForm();
  const { setGroupId, setMessageNotificationFeedback, setOpenNotificationFeedback } = useGroupEconomyReports();
  const [showConfirmationForm, setShowConfirmationForm] = useState<boolean>(false);

  const reportNotificationFeedback = initDataForm?.id
    ? EconomyNotificationFeedbackContents.UPDATING_ECONOMY_REPORT
    : EconomyNotificationFeedbackContents.CREATING_ECONOMY_REPORT;

  const methods = useForm<EconomyReportPayload>({
    defaultValues: initDataForm ?? EMPTY_REPORT,
    mode: 'onBlur',
  });
  const unitValue = methods.watch('unitId');
  const aclEnergyProvidedCost = methods.watch('aclEnergyProvidedCost');
  const aclEnergyProvided = methods.watch('aclEnergyProvided');
  const groupValue = methods.watch('groupId');
  const date = methods.watch('date');

  if (groupValue) setGroupId(groupValue);

  const unitFormData = groupReportFormData.units && filterUnit(groupReportFormData.units, unitValue);
  const unitInitFormData = initDataForm as EconomyReportPayload;

  useEffect(() => {
    if (shouldResetForm) {
      methods.reset(EMPTY_REPORT);
    }
    methods.clearErrors();
  }, [shouldResetForm]);

  useEffect(() => {
    if (date && date.length === 6) {
      if (date === '072024') {
        methods.setValue('acrTariffFlagCost', 18.85);
      } else if (date === '092024') {
        methods.setValue('acrTariffFlagCost', 44.63);
      } else {
        methods.resetField('acrTariffFlagCost');
      }
    }
  }, [date]);

  useEffect(() => {
    if (date && date.length === 6) {
      const dateFormated = formatDateFromString(date, 'MMYYYY', 'YYYY-MM-DD');

      if (unitFormData && unitFormData.retailService === false) {
        getEnergyAuditsByGroupIdHandler(groupReportFormData.groupId, dateFormated);
        getAllFinancialEventsByGroupIdHandler(groupReportFormData.groupId, dateFormated);
      }
    }
  }, [unitFormData, initDataForm, date]);

  useEffect(() => {
    if (aclEnergyProvidedCost && aclEnergyProvided) {
      return;
    }

    if (date && date.length === 6) {
      const dateTimeFormated = formatDateFromString(date, 'MMYYYY', 'YYYY-MM');

      if (unitFormData?.docNumber) {
        getSupplyInvoiceHandler(dateTimeFormated, unitFormData.docNumber);
      }

      if (supplySection?.supplyAmountMwh) {
        methods.setValue('aclEnergyProvided', supplySection?.supplyAmountMwh);
      }
      if (supplySection?.price) {
        methods.setValue('aclEnergyProvidedCost', supplySection?.price);
      }
    }
  }, [
    unitFormData?.docNumber,
    date,
    supplySection.price,
    supplySection.supplyAmountMwh,
    aclEnergyProvidedCost,
    aclEnergyProvided,
  ]);

  useEffect(() => {
    if (unitsConsumptionFinancialEvent.units.length) {
      const unitsResults = calcFinancialAmountsFromUnitsConsumption(unitsConsumptionFinancialEvent);

      if (unitFormData) {
        const unitResult = unitsResults.find(({ id }) => unitValue === id);
        methods.setValue('aclEssCost', unitResult?.liquidationAmount);
        methods.setValue('aclCceeCost', unitResult?.cceeAmount);
        methods.setValue('aclEerCost', unitResult?.eerAmount);
      }
    }
  }, [unitFormData, unitsConsumptionFinancialEvent]);

  useEffect(() => {
    if (unitFormData) {
      methods.setValue('aclContractType', unitFormData.aclContractType);
      methods.setValue('tariffType', unitFormData?.tariffType);
      methods.setValue('devecState', unitFormData?.devecState);
      methods.setValue('edcId', unitFormData?.edcId);
      methods.setValue('aclContractedDemandOffPeak', unitFormData?.aclContractedDemandOffPeak);
      methods.setValue('aclContractedDemandPeak', unitFormData?.aclContractedDemandPeak);
      methods.setValue('acrContractedDemandOffPeak', unitFormData?.acrContractedDemandOffPeak);
      methods.setValue('acrContractedDemandPeak', unitFormData?.acrContractedDemandPeak);
      methods.setValue('aclIcmsDistribution', unitFormData?.aclIcmsDistribution);
      methods.setValue('aclIcmsSupply', unitFormData?.aclIcmsSupply);

      if (unitInitFormData?.clarkeManagementCost) {
        methods.setValue('clarkeManagementCost', unitInitFormData?.clarkeManagementCost);
      } else {
        methods.setValue('clarkeManagementCost', unitFormData.clarkeManagementCost);
      }

      if (unitInitFormData?.traderId) {
        methods.setValue('traderId', unitInitFormData.traderId);
      } else {
        methods.setValue('traderId', unitFormData.traderId);
      }

      if (unitFormData.devecState === null) {
        methods.setValue('devecDeclaration', null);
      }

      if (unitFormData.retailService && !unitFormData.displayChargesForRetail) {
        methods.setValue('aclCceeCost', null);
        methods.setValue('aclEerCost', null);
        methods.setValue('aclEssCost', null);
      }

      if (unitFormData.aclContractType === 'GUARANTEED_SAVING') {
        methods.setValue('aclEnergyProvidedCost', unitFormData.aclEnergyProvidedCost);
      }
    }
  }, [groupReportFormData, unitFormData, initDataForm]);

  const onSubmit: SubmitHandler<EconomyReportPayload> = async (values) => {
    const {
      date,
      aclReadDemandPeak,
      aclReactiveDemandPeak,
      aclContractedDemandPeak,
      acrContractedDemandPeak,
      devecState,
      aclCovidCost,
      aclDelays,
      aclDistributionAdjustmentValue,
      aclEnergyProvidedAdjustmentValue,
      aclEerCost,
      aclCceeCost,
      aclEssCost,
      aclLoadFactor,
      ...restValues
    } = values;

    return upsertEconomyReportHandler({
      date: formatDateFromString(date || '', 'MMYYYY', 'YYYY-MM-DD'),
      ...(initDataForm?.id && { id: initDataForm?.id }),
      ...(devecState && { devecState }),
      ...(aclReadDemandPeak && { aclReadDemandPeak }),
      ...(aclLoadFactor && { aclLoadFactor }),
      ...(aclReactiveDemandPeak && { aclReactiveDemandPeak }),
      ...(aclContractedDemandPeak && { aclContractedDemandPeak }),
      ...(acrContractedDemandPeak && { acrContractedDemandPeak }),
      ...(aclCovidCost && { aclCovidCost }),
      ...(aclDelays && { aclDelays }),
      ...(aclEerCost && { aclEerCost }),
      ...(aclCceeCost && { aclCceeCost }),
      ...(aclEssCost && { aclEssCost }),
      ...(aclEnergyProvidedAdjustmentValue && { aclEnergyProvidedAdjustmentValue }),
      ...(aclDistributionAdjustmentValue && { aclDistributionAdjustmentValue }),
      ...restValues,
    })
      .then((res) => {
        setOpenNotificationFeedback(true);
        window.open(`/relatorio-unidade/${res.data.createEconomyReport.id}`, '_blank');
        setMessageNotificationFeedback(reportNotificationFeedback);
        onFormSuccess();
      })
      .catch(() => {
        onFormFail();
        setShowConfirmationForm(false);
      });
  };

  return (
    <div className="col-span-full -mt-[60px]">
      <FormProvider {...methods}>
        <form>
          <div className="grid grid-cols-1 gap-y-6 pt-8 lg:gap-y-16">
            <CustomerInputs
              groups={[{ id: groupReportFormData.groupId, name: groupReportFormData.name }]}
              units={groupReportFormData.units}
              dataToUpdate={initDataForm?.id ? initDataForm : null}
              setShowForm={setShowForm}
            />
            <DistributionAclInputs
              tariffModality={unitFormData?.tariffType || TariffModality.A4_GREEN}
              devecState={unitFormData?.devecState || null}
            />
            <TradersProvider>
              <ProvidedAclInputs
                contractType={unitFormData?.aclContractType ?? 'FIXED_PRICE'}
                retailService={unitFormData?.retailService ?? false}
                chargesForRetailService={(unitFormData?.retailService && unitFormData.displayChargesForRetail) ?? false}
              />
            </TradersProvider>
            <SimulationAcrInputs />
          </div>
          <div className="flex gap-5 my-14 w-fit">
            <Button
              kind="primary"
              type="button"
              label={`${initDataForm?.id ? 'Editar' : 'Gerar'} Relatório`}
              onClick={() => {
                methods.trigger().then((isValid) => {
                  setShowConfirmationForm(isValid);
                });
              }}
            />
            <Button label="Cancelar" type="button" onClick={() => closeFormAction()} kind="secondary" />
          </div>
        </form>
        <Modal
          title={`${initDataForm?.id ? 'Editar' : 'Gerar'} Relatório?`}
          description={`Confirme as informações inseridas antes de ${
            initDataForm?.id ? 'editar' : 'gerar'
          } o relatório.`}
          open={showConfirmationForm}
          setOpen={() => setShowConfirmationForm(!showConfirmationForm)}
        >
          <FormConfirmation
            text={`${initDataForm?.id ? 'Editar' : 'Gerar'} Relatório`}
            onConfirmation={() => methods.handleSubmit(onSubmit)()}
            onCancel={() => setShowConfirmationForm(false)}
          />
        </Modal>
      </FormProvider>
    </div>
  );
};

export default EconomyReportForm;
