import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@clarke-energia/foton';

import { ComercialGroupTypeEnum, CommercialGroupPayload, EMPTY_GROUP_PAYLOAD } from '@contexts/commercial-group/types';
import useCommercialGroups from '@hooks/use-commercial-group';

import { FormField, GroupSelectInputFormField, SelectFormField } from '@components/atoms/form';

import './style.css';

const comercialGroupTypeOptions = [
  { value: ComercialGroupTypeEnum.RETAIL, optionLabel: 'Varejo' },
  { value: ComercialGroupTypeEnum.MANAGEMENT_WHOLESALE, optionLabel: 'Gestão (atacadista)' },
  { value: ComercialGroupTypeEnum.MANAGEMENT_RETAIL, optionLabel: 'Gestão (varejista)' },
];

interface IForm {
  dataToUpdate: CommercialGroupPayload | null;
  onFormSuccess: () => void;
  onFormFail: () => void;
  setOpenCreateCommercialGroup: (open: boolean) => void;
}

function FormCreateCommercialGroup({ onFormSuccess, onFormFail, dataToUpdate, setOpenCreateCommercialGroup }: IForm) {
  const { createCommercialGroupHandler, updateCommercialGroupHandler, loading } = useCommercialGroups();

  const methods = useForm<CommercialGroupPayload>({
    defaultValues: dataToUpdate ? dataToUpdate : EMPTY_GROUP_PAYLOAD,
  });
  const docType = methods.watch('docType');

  const docNumberFormat = {
    placeholder: docType === 'CNPJ' ? '00.000.000/0000-00' : '000.000.000-00',
    format: docType === 'CNPJ' ? '##.###.###/####-##' : '###.###.###-##',
    mask: '_',
    allowNegative: false,
    fixedDecimalScale: false,
    decimalSeparator: undefined,
    thousandSeparator: undefined,
    decimalScale: 0,
    isNumericString: true,
  };

  const docOptions = [
    { value: 'CNPJ', label: 'CNPJ' },
    { value: 'CPF', label: 'CPF' },
  ];

  const onSubmit: SubmitHandler<CommercialGroupPayload> = async (values) => {
    const dataHandler = dataToUpdate ? updateCommercialGroupHandler : createCommercialGroupHandler;
    return dataHandler({
      ...values,
    })
      .then(() => {
        onFormSuccess();
      })
      .catch(() => {
        onFormFail();
        setOpenCreateCommercialGroup(false);
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3 pt-4 -mb-8">
        <FormField<CommercialGroupPayload>
          id="name"
          label="Nome"
          field="name"
          placeholder="Cliente Exemplo"
          options={{ required: { value: true, message: 'O nome é obrigatório' } }}
        />
        <FormField<CommercialGroupPayload>
          id="legalName"
          label="Razão Social"
          field="legalName"
          placeholder="Cliente Exemplo LTDA"
        />
        <GroupSelectInputFormField<CommercialGroupPayload>
          firstField="docNumber"
          secondField="docType"
          id="docNumber"
          selectOptions={docOptions}
          label="Documento"
          type="number"
          formatProps={docNumberFormat}
          options={{
            required: { value: true, message: 'O documento é obrigatório' },
            validate: (value) => {
              if (docType === 'CNPJ' && value.length !== 14) {
                return 'O cnpj deve conter 14 dígitos.';
              }
              if (docType === 'CPF' && value.length !== 11) {
                return 'O cpf deve conter 11 dígitos.';
              }
            },
            onChange: (e) => {
              const value = e.target.value;
              if (value === 'CNPJ' || value === 'CPF') methods.setValue('docType', value);
            },
          }}
        />

        <SelectFormField<CommercialGroupPayload>
          label="Tipo de cliente"
          field="type"
          id="comercialGroupType"
          inputOptions={comercialGroupTypeOptions}
          options={{ required: { value: true, message: 'Campo é obrigatório' } }}
        />

        <Button
          label={`${dataToUpdate ? 'Atualizar' : 'Criar'} cliente`}
          kind={'primary'}
          loading={loading}
          className="mt-5"
        />
      </form>
    </FormProvider>
  );
}

export default FormCreateCommercialGroup;
