import { gql } from '@apollo/client';

export const GET_MIGRATION_GROUPS_DATA_QUERY = gql`
  query GetGroupsManagement {
    getGroupsManagement {
      group {
        id
        name
      }
      thirdPartyId
    }
  }
`;

export const GET_GROUPS_DATA_QUERY = gql`
  query GetAllCommercialGroups {
    groups {
      data {
        id
        name
      }
    }
  }
`;

export const GET_MIGRATION_REPORTS_QUERY = gql`
  query GetMigrationReports($page: Int, $groupName: String, $thirdPartyIds: [String], $progress: ReportProgress) {
    getMigrationReportsByPage(page: $page, groupName: $groupName, thirdPartyIds: $thirdPartyIds, progress: $progress) {
      page
      limit
      total
      data {
        id
        group {
          id
          users {
            id
            roles
            user {
              id
            }
          }
        }
        thirdPartyId
        commercialGroup
        conclusionPercentage
        manualSyncStatus
        units {
          nickname
          conclusionPercentage
        }
      }
    }
  }
`;
