import { NuggetInfoProps } from '@clarke-energia/foton';
import { EDC } from '@hooks/edcs';
import { formatCNPJ, formatCPF, formatCurrency, formatNumberToStringWithSuffix } from '@utils/text';
import { formatDate, parseStringToDate } from '@utils/dayjs';
import { IUnitsManagement, UnitPayload, UNIT_DOC_TYPE_CNPJ } from './types';

export const parserUnitManagement = (units: UnitPayload[], groupId: string) => {
  const unitsManagement: IUnitsManagement[] = [];
  units.forEach((unit) => {
    unitsManagement.push({ unitId: unit.id, name: unit.name, groupId });
  });
  return unitsManagement;
};

const submarketGetter = {
  NORTH: 'Norte',
  NORTHEAST: 'Nordeste',
  SOUTH: 'Sul',
  SOUTHEAST: 'Sudeste',
};

const unitTypeGetter = {
  COMMERCIAL: 'Comercial',
  RURAL: 'Rural',
  INDUSTRIAL: 'Industrial',
};

const tariffModeGetter = {
  GREEN: 'Verde',
  BLUE: 'Azul',
};

export const parseUnitToNuggetInfo = (unitInfos: UnitPayload, edcs: EDC[]): NuggetInfoProps[] => {
  const isBlue = unitInfos.tariffModality === 'BLUE';

  return [
    {
      id: '1',
      label: 'Nome',
      textInfo: unitInfos.name ?? '-',
    },
    {
      id: '2',
      label: 'Razão Social',
      textInfo: unitInfos.legalName ?? '-',
    },
    {
      id: '3',
      label: unitInfos.docType === 2 ? 'CNPJ' : 'CPF',
      textInfo:
        unitInfos.docType === UNIT_DOC_TYPE_CNPJ ? formatCNPJ(unitInfos.docNumber) : formatCPF(unitInfos.docNumber),
    },
    {
      id: '4',
      label: 'Classificação',
      textInfo: unitInfos.unitType ? unitTypeGetter[unitInfos.unitType] : '-',
    },
    {
      id: '5',
      label: 'Submercado',
      textInfo: unitInfos.submarket ? submarketGetter[unitInfos.submarket] : '-',
    },
    {
      id: '6',
      label: 'Estado',
      textInfo: unitInfos.state ?? '-',
    },
    {
      id: '7',
      label: 'Distribuidora',
      textInfo: edcs?.find((edc) => edc.id === unitInfos.edc)?.name ?? '-',
    },
    {
      id: '8',
      label: 'Subgrupo',
      textInfo: unitInfos.tariffSubgroup?.toString() ?? '-',
    },
    {
      id: '9',
      label: 'Modalidade tarifária',
      textInfo: unitInfos.tariffModality ? tariffModeGetter[unitInfos.tariffModality] : '-',
    },
    {
      id: '10',
      label: 'Demanda Contratada FP',
      textInfo: formatNumberToStringWithSuffix(unitInfos.contractedDemandOffPeak ?? 0, 'kW', 0),
    },
    {
      id: '10',
      label: 'Demanda Contratada P',
      textInfo:
        unitInfos.contractedDemandPeak && isBlue
          ? formatNumberToStringWithSuffix(unitInfos.contractedDemandPeak ?? 0, 'kW', 0)
          : '-',
    },
    {
      id: '11',
      label: 'Demanda FP no ACR',
      textInfo: formatNumberToStringWithSuffix(unitInfos.contractedDemandOffPeakBeforeMigration ?? 0, 'kW', 0),
    },
    {
      id: '11',
      label: 'Demanda P no ACR',
      textInfo:
        unitInfos.contractedDemandPeakBeforeMigration && isBlue
          ? formatNumberToStringWithSuffix(unitInfos.contractedDemandPeakBeforeMigration ?? 0, 'kW', 0)
          : '-',
    },
    {
      id: '12',
      label: 'Valor mensal da fatura',
      textInfo: formatCurrency(unitInfos.monthlyInvoice ?? 0),
    },
    {
      id: '13',
      label: 'Data de migração',
      textInfo: unitInfos.migrationDate ? formatDate(parseStringToDate(unitInfos.migrationDate), 'DD/MM/YYYY') : '-',
    },
    {
      id: '14',
      label: 'Gerador',
      textInfo: unitInfos.powerGenerator ? 'Com gerador' : 'Sem gerador',
    },
  ];
};
