import React from 'react';

import { useFormContext } from 'react-hook-form';
import { CreateEnergyContractPayload } from '@contexts/energy-contract/types';
import { ReadjustmentFrequencyEnum, ReadjustmentIndexEnum } from '@utils/translators/energy-contract';
import { NumericFormField, FormSection, SelectFormField } from '@components/atoms/form';

interface PriceSectionProps {
  yearsFields: Record<'id', string>[];
  startYear: number;
  isFixedContractType: boolean;
}

const FormPriceSection = ({ yearsFields, startYear, isFixedContractType }: PriceSectionProps): JSX.Element => {
  const { setValue } = useFormContext();

  const formatProps = {
    placeholder: isFixedContractType ? 'R$' : '%',
    prefix: isFixedContractType ? 'R$' : '',
    suffix: isFixedContractType ? '' : '%',
    allowNegative: true,
    fixedDecimalScale: true,
    decimalScale: 2,
  };

  return (
    <div>
      <div className="gap-6 mb-7 lg:grid lg:grid-cols-3 xl:grid-cols-4 xl:mb-10">
        <FormSection
          title="Preço da energia"
          subtitle="Valores acertados no contrato de energia e informações de reajuste."
        />
        <div className="col-end-5 gap-x-6 gap-y-6 w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
          {yearsFields.map((item, idx) => (
            <NumericFormField
              key={`year-field-${idx}`}
              field={`economy.${idx}.amount`}
              id={`economy${idx}.amount`}
              label={`${startYear + idx}`}
              formatProps={formatProps}
              options={{
                required: { value: true, message: 'Campo é obrigatório' },
                onChange: () => {
                  setValue(`economy.${idx}.year`, startYear + idx);
                },
              }}
            />
          ))}
          {isFixedContractType ? (
            <>
              <SelectFormField<CreateEnergyContractPayload>
                label="Índice de reajuste"
                field="readjustmentIndex"
                id="readjustmentIndex"
                inputOptions={Object.keys(ReadjustmentIndexEnum).map((key) => ({
                  value: key,
                  optionLabel: key,
                }))}
              />
              <NumericFormField<CreateEnergyContractPayload>
                id="readjustmentBaseDate"
                field="readjustmentBaseDate"
                label="Data base do reajuste"
                formatProps={{
                  placeholder: 'DD/MM/AAAA',
                  format: '##/##/####',
                  mask: '_',
                  allowNegative: false,
                  fixedDecimalScale: false,
                  decimalSeparator: undefined,
                  thousandSeparator: undefined,
                  decimalScale: 0,
                  isNumericString: true,
                }}
              />
              <SelectFormField<CreateEnergyContractPayload>
                label="Periodicidade do reajuste"
                field="readjustmentFrequency"
                id="readjustmentFrequency"
                inputOptions={Object.keys(ReadjustmentFrequencyEnum).map((key) => ({
                  value: key,
                  optionLabel: ReadjustmentFrequencyEnum[key as keyof typeof ReadjustmentFrequencyEnum],
                }))}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FormPriceSection;
