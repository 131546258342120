import { UnitPayload } from '@contexts/unit';
import { UserInfo } from '@contexts/user/types';

export enum GroupDoc {
  CNPJ = 'CNPJ',
  CPF = 'CPF',
}

export interface GroupsListGraphQLData {
  groups: { data: CommercialGroup[] };
}

export enum ComercialGroupTypeEnum {
  MANAGEMENT_RETAIL = 'MANAGEMENT_RETAIL',
  MANAGEMENT_WHOLESALE = 'MANAGEMENT_WHOLESALE',
  RETAIL = 'RETAIL',
}

export interface CommercialGroup {
  id: string;
  name: string;
  legalName: string;
  docType: keyof typeof GroupDoc;
  docNumber: string;
  users: UserInfo[];
  unitsResume?: UnitsResume;
  units?: UnitPayload[];
  type: ComercialGroupTypeEnum;
}

export interface CommercialGroupPayload {
  id: string;
  name: string;
  legalName: string;
  docType: keyof typeof GroupDoc;
  docNumber: string;
  type: ComercialGroupTypeEnum;
}

export const EMPTY_GROUP_PAYLOAD: CommercialGroupPayload = {
  id: '',
  name: '',
  legalName: '',
  docType: GroupDoc.CNPJ,
  docNumber: '',
  // @ts-expect-error - this field is required
  type: '',
};

export interface UnitsResume {
  totalVolume: number;
  totalAmount: number;
  totalContractedDemandPeak: number;
  totalContractedDemandOffPeak: number;
}

export interface IGroupsManagementVariables {
  input: { groupId: string; name: string };
}

export interface IGroupsManagementResult {
  id: string;
}

export interface IUnitsProgress {
  nickname: string;
  conclusionPercentage: string;
  thirdPartyId: string;
}

export interface IUnitsMigrationReport {
  thirdPartyId: string;
  name: string;
}

export interface IGroupSyncGraphQlResult {
  syncMigrationReport: {
    success: boolean;
    message: string;
  };
}

export interface Proposal {
  id: string;
  round: number;
  status?: number;
  createdAt?: string;
  traders: RetailTrader[];
  bids: Bid[];
}

export interface RetailTrader {
  id?: string;
  name: string;
  users: {
    id: string;
    name: string;
    email: string;
  }[];
}

export interface Bid {
  id: string;
  winnerAt?: string;
  trader: RetailTrader;
}
