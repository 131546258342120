import React from 'react';
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { Select } from '@clarke-energia/foton';
import { get } from 'lodash';

export interface SelectFormFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  label: string;
  placeholder?: string;
  id: string;
  inputOptions: { optionLabel: string; value: any }[];
  options?: RegisterOptions;
  transform?: {
    input?: (value: number | string) => number | string;
    output?: (value: number | string) => number | string;
  };
  tooltipContent?: string;
  disabled?: boolean;
  defaultValue?: { optionLabel: string; value: any };
}

export function SelectFormField<T extends FieldValues>({
  field,
  label,
  id,
  inputOptions,
  placeholder,
  options,
  transform,
  tooltipContent,
  disabled,
  defaultValue,
}: SelectFormFieldProps<T>) {
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext<T>();

  const fieldError = get(errors, field)?.message as string | undefined;
  React.useEffect(() => {
    if (defaultValue) {
      setValue(field, defaultValue.value);
    }
  }, []);
  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <Select
          id={id}
          name={name}
          label={label}
          options={inputOptions
            .map(({ optionLabel, value }, _) => ({
              display: optionLabel,
              value: value,
            }))
            .sort((a, b) => a.display.localeCompare(b.display))}
          value={transform?.input ? transform.input(value) : value}
          placeholder={placeholder || 'Selecione uma opção'}
          required={(options?.required as boolean) || false}
          error={fieldError}
          onBlur={onBlur}
          onChange={(e) => {
            const newValue = e.target.value;
            onChange(transform?.output ? transform.output(newValue) : newValue);
          }}
          tooltipContent={tooltipContent}
          disabled={disabled}
        />
      )}
    />
  );
}
