import { ColumnOptions, IconProps } from '@clarke-energia/foton';

import {
  ConventionalMarketTableData,
  FreeMarketTableData,
  IGeneralReportTable,
  IGeneralReportTableData,
  IMinimalGeneralReportTable,
} from '@contexts/unit-economy-report/types';

type cards = {
  icon: IconProps['icon'];
  sublabel: string;
  altColor: string;
  altLabel: string;
  label: string;
};

export type customer = {
  groupName: string;
  unitName: string;
  edcName: string;
  subgroup: string;
  tariffModality: 'Verde' | 'Azul';
  contractedDemandPeak: string;
  contractedDemandOffPeak: string;
  cityAndState: string;
};

export const cardsInfo = (
  currentSavings: string,
  savingsPercent: string,
  energyTotalCost: string,
  energyTotalConsumption: string,
): cards[] => [
  {
    icon: 'FaceSmileIcon',
    sublabel: 'Economia',
    altColor: 'primary-60',
    label: currentSavings,
    altLabel: savingsPercent || '',
  },
  {
    icon: 'CurrencyDollarIcon',
    sublabel: 'Custo Total de Energia',
    altColor: '',
    label: energyTotalCost,
    altLabel: '',
  },
  { icon: 'LightBulbIcon', sublabel: 'Consumo de Energia', altColor: '', label: energyTotalConsumption, altLabel: '' },
];

export const displayUnitCustomerInfo = ({
  groupName,
  unitName,
  edcName,
  subgroup,
  tariffModality,
  contractedDemandPeak,
  contractedDemandOffPeak,
  cityAndState,
}: customer) => {
  return [
    { title: 'Empresa', value: groupName, style: 'xl:block hidden' },
    { title: 'Unidade', value: unitName, style: 'xl:block hidden' },
    { title: null, value: null, style: 'xl:block hidden' },
    { title: 'Distribuidora', value: edcName },
    { title: 'Subgrupo', value: subgroup },
    { title: 'Modalidade Tarifária', value: tariffModality },
    {
      title: 'Demanda Contratada Fora Ponta',
      value: contractedDemandOffPeak,
    },
    {
      title: 'Demanda Contratada Ponta',
      value: contractedDemandPeak,
    },
    {
      title: 'Estado',
      value: cityAndState,
    },
  ];
};

const generalMarketColumnDefinitions: ColumnOptions<IGeneralReportTable>[] = [
  { accessor: 'item', header: 'Item', renderAsElement: (report) => report.item },
  { accessor: 'quantity', header: 'Quantidade', renderAsElement: (report) => report.quantity },
  { accessor: 'tariff', header: 'Tarifa', renderAsElement: (report) => report.tariff },
  { accessor: 'total', header: 'Total', renderAsElement: (report) => report.total },
];

const generalMarketMinimalColumnDefinitions: ColumnOptions<any>[] = [
  { accessor: 'item', header: 'Item', renderAsElement: (report) => report.item },
  { accessor: 'total', header: 'Total', renderAsElement: (report) => report.total },
];

export const freeMarketTablesGenerator = (freeMarketTableData: FreeMarketTableData): IGeneralReportTableData<any>[] => {
  const distributionTable: IGeneralReportTableData<any> = {
    columnDefinitions: generalMarketColumnDefinitions,
    superheader: `Distribuição-${freeMarketTableData?.energyDistribution?.edcName}`,
    data: [
      ...(freeMarketTableData?.energyDistribution?.billedDemandPeakAmount !== '0,00'
        ? [
            {
              item: 'Demanda Faturada Ponta (kW)',
              quantity: freeMarketTableData?.energyDistribution?.billedDemandPeakAmount,
              tariff: freeMarketTableData?.energyDistribution?.billedDemandPeakCost,
              total: freeMarketTableData?.energyDistribution?.billedDemandPeakTotal,
            },
          ]
        : []),
      {
        item:
          freeMarketTableData?.energyDistribution?.billedDemandPeakAmount !== '0'
            ? 'Demanda Faturada Fora Ponta (kW)'
            : 'Demanda Faturada (kW)',
        quantity: freeMarketTableData?.energyDistribution?.billedDemandOffPeakAmount,
        tariff: freeMarketTableData?.energyDistribution?.billedDemandOffPeakCost,
        total: freeMarketTableData?.energyDistribution?.billedDemandOffPeakTotal,
      },
      ...(freeMarketTableData?.energyDistribution?.acrBilledDemandPeakTotal !== 'R$ 0,00'
        ? [
            {
              item: 'Demanda Ponta ACR (kW)',
              quantity: freeMarketTableData?.energyDistribution?.acrBilledDemandPeakAmount,
              tariff: freeMarketTableData?.energyDistribution?.acrBilledDemandPeakCost,
              total: freeMarketTableData?.energyDistribution?.acrBilledDemandPeakTotal,
            },
          ]
        : []),
      ...(freeMarketTableData?.energyDistribution?.acrBilledDemandOffPeakTotal !== 'R$ 0,00'
        ? [
            {
              item: 'Demanda Fora Ponta ACR (kW)',
              quantity: freeMarketTableData?.energyDistribution?.acrBilledDemandOffPeakAmount,
              tariff: freeMarketTableData?.energyDistribution?.acrBilledDemandOffPeakCost,
              total: freeMarketTableData?.energyDistribution?.acrBilledDemandOffPeakTotal,
            },
          ]
        : []),
      {
        item: 'TUSD Ponta (kW)',
        quantity: freeMarketTableData?.energyDistribution?.tusdPeakAmount,
        tariff: freeMarketTableData?.energyDistribution?.tusdPeakCost,
        total: freeMarketTableData?.energyDistribution?.tusdPeakTotal,
      },
      {
        item: 'TUSD Fora Ponta (kW)',
        quantity: freeMarketTableData?.energyDistribution?.tusdOffPeakAmount,
        tariff: freeMarketTableData?.energyDistribution?.tusdOffPeakCost,
        total: freeMarketTableData?.energyDistribution?.tusdOffPeakTotal,
      },
      ...(freeMarketTableData?.energyDistribution?.acrTePeakTotal !== 'R$ 0,00'
        ? [
            {
              item: 'TE Ponta ACR (MWh)',
              quantity: freeMarketTableData?.energyDistribution?.acrTePeakAmount,
              tariff: freeMarketTableData?.energyDistribution?.acrTePeakCost,
              total: freeMarketTableData?.energyDistribution?.acrTePeakTotal,
            },
          ]
        : []),
      ...(freeMarketTableData?.energyDistribution?.acrTeOffPeakTotal !== 'R$ 0,00'
        ? [
            {
              item: 'TE Fora Ponta ACR (MWh)',
              quantity: freeMarketTableData?.energyDistribution?.acrTeOffPeakAmount,
              tariff: freeMarketTableData?.energyDistribution?.acrTeOffPeakCost,
              total: freeMarketTableData?.energyDistribution?.acrTeOffPeakTotal,
            },
          ]
        : []),
      ...(freeMarketTableData?.energyDistribution?.covidTariffTotal !== 'R$ 0,00'
        ? [
            {
              item: 'Encargo COVID-19 + Escassez Hídrica',
              quantity: freeMarketTableData?.energyDistribution?.covidTariffAmount,
              tariff: freeMarketTableData?.energyDistribution?.covidTariffCost,
              total: freeMarketTableData?.energyDistribution?.covidTariffTotal,
            },
          ]
        : []),
      ...(freeMarketTableData?.energyDistribution?.overtakingTotal !== 'R$ 0,00'
        ? [
            {
              item: 'Ultrapassagem',
              quantity: '-',
              tariff: '-',
              total: freeMarketTableData?.energyDistribution?.overtakingTotal,
            },
          ]
        : []),
      {
        item: 'Reativos',
        quantity: '-',
        tariff: '-',
        total: freeMarketTableData?.energyDistribution?.reactivesTotal,
      },
      {
        item: 'Impostos',
        quantity: '-',
        tariff: '-',
        total: freeMarketTableData?.energyDistribution?.taxesTotal,
      },
      {
        item: 'Outros',
        quantity: '-',
        tariff: '-',
        total: freeMarketTableData?.energyDistribution?.others,
      },
      {
        item: 'Total Distribuição (c/impostos)',
        quantity: '',
        tariff: '',
        total: freeMarketTableData?.energyDistribution?.totalValue,
      },
    ],
  };

  const providedEnergyTable: IGeneralReportTableData<IGeneralReportTable> = {
    columnDefinitions: generalMarketColumnDefinitions,
    data: [
      {
        item: `${freeMarketTableData?.energyProvided.traderName} (MWh)`,
        quantity: freeMarketTableData?.energyProvided.energyProvidedAmount,
        tariff: freeMarketTableData?.energyProvided.energyProvidedCost,
        total: freeMarketTableData?.energyProvided.energyProvidedTotal,
      },
      {
        item: '↪ Destaque PIS/COFINS',
        quantity: '-',
        tariff: freeMarketTableData?.energyProvided.highlightPisCofinsCost,
        total: freeMarketTableData?.energyProvided.highlightPisCofinsTotal,
      },
      ...(freeMarketTableData?.energyProvided.energyProvidedAdjustmentValue !== null
        ? [
            {
              item: 'Devoluções/Ajustes',
              quantity: '-',
              tariff: '-',
              total: freeMarketTableData?.energyProvided.energyProvidedAdjustmentValue,
            },
          ]
        : []),
      ...(freeMarketTableData?.energyProvided.devec === null
        ? [
            {
              item: 'ICMS',
              quantity: '-',
              tariff: freeMarketTableData?.energyProvided.icmsCost,
              total: freeMarketTableData?.energyProvided.icmsTotal,
            },
          ]
        : []),
      {
        item: 'Total Fornecimento (c/ impostos)',
        quantity: '',
        tariff: '',
        total: freeMarketTableData?.energyProvided.totalValue,
      },
    ],
    superheader: 'Fornecimento',
  };

  const othersTable: IGeneralReportTableData<IMinimalGeneralReportTable> = {
    columnDefinitions: generalMarketMinimalColumnDefinitions,
    data: [
      {
        item: 'Gestão Clarke',
        total: freeMarketTableData?.others.clarkeManagementCost,
      },
      ...(freeMarketTableData?.others.eerCost !== 'R$ 0,00'
        ? [
            {
              item: 'EER',
              total: freeMarketTableData?.others.eerCost,
            },
          ]
        : []),
      ...(freeMarketTableData?.others.essCost !== 'R$ 0,00'
        ? [
            {
              item: 'Liquidação Financeira + ESS',
              total: freeMarketTableData?.others.essCost,
            },
          ]
        : []),
      ...(freeMarketTableData?.others.cceeCost !== 'R$ 0,00'
        ? [
            {
              item: 'Contribuição Associativa',
              total: freeMarketTableData?.others.cceeCost,
            },
          ]
        : []),
      ...(freeMarketTableData?.others.totalValue !== 'R$ 0,00'
        ? [
            {
              item: 'Total Encargos',
              total: freeMarketTableData?.others.totalValue,
            },
          ]
        : []),
    ],
    superheader: 'Encargos / Outros',
  };

  return [distributionTable, providedEnergyTable, othersTable];
};

export const conventionalMarketTablesGenerator = (
  conventionalMarketTableData: ConventionalMarketTableData,
): IGeneralReportTableData<any>[] => {
  const distributionTable: IGeneralReportTableData<any> = {
    columnDefinitions: generalMarketColumnDefinitions,
    superheader: `Distribuição-${conventionalMarketTableData?.edcName}`,
    data: [
      ...(conventionalMarketTableData?.billedDemandPeakAmount !== '0,00'
        ? [
            {
              item: 'Demanda Ponta (kW)',
              quantity: conventionalMarketTableData?.billedDemandPeakAmount,
              tariff: conventionalMarketTableData?.billedDemandPeakCost,
              total: conventionalMarketTableData?.billedDemandPeakTotal,
            },
          ]
        : []),
      {
        item: 'Demanda Fora Ponta (kW)',
        quantity: conventionalMarketTableData?.billedDemandOffPeakAmount,
        tariff: conventionalMarketTableData?.billedDemandOffPeakCost,
        total: conventionalMarketTableData?.billedDemandOffPeakTotal,
      },
      {
        item: 'TUSD Ponta (MWh)',
        quantity: conventionalMarketTableData?.tusdPeakAmount,
        tariff: conventionalMarketTableData?.tusdPeakCost,
        total: conventionalMarketTableData?.tusdPeakTotal,
      },
      {
        item: 'TUSD Fora Ponta (MWh)',
        quantity: conventionalMarketTableData?.tusdOffPeakAmount,
        tariff: conventionalMarketTableData?.tusdOffPeakCost,
        total: conventionalMarketTableData?.tusdOffPeakTotal,
      },
      {
        item: 'TE Ponta (MWh)',
        quantity: conventionalMarketTableData?.tePeakAmount,
        tariff: conventionalMarketTableData?.tePeakCost,
        total: conventionalMarketTableData?.tePeakTotal,
      },
      {
        item: 'TE Fora Ponta (MWh)',
        quantity: conventionalMarketTableData?.teOffPeakAmount,
        tariff: conventionalMarketTableData?.teOffPeakCost,
        total: conventionalMarketTableData?.teOffPeakTotal,
      },
      {
        item: 'Ultrapassagem',
        quantity: '-',
        tariff: '-',
        total: conventionalMarketTableData?.overtakingTotal,
      },
      {
        item: 'Reativos',
        quantity: '-',
        tariff: '-',
        total: conventionalMarketTableData?.reactivesTotal,
      },
      {
        item: 'Bandeira Tarifária',
        quantity: '-',
        tariff: conventionalMarketTableData?.tariffFlagCost,
        total: conventionalMarketTableData?.tariffFlagTotal,
      },
      {
        item: 'Impostos',
        quantity: '-',
        tariff: '-',
        total: conventionalMarketTableData?.taxesTotal,
      },
      {
        item: 'Outros',
        quantity: '-',
        tariff: '-',
        total: conventionalMarketTableData?.others,
      },
      {
        item: 'Total',
        quantity: '-',
        tariff: '-',
        total: conventionalMarketTableData?.totalValue,
      },
    ],
  };

  return [distributionTable];
};
