import React from 'react';
import { TextInput } from '@clarke-energia/foton';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { get } from 'lodash';

export interface GenericFormFieldProps<T extends FieldValues> {
  field: Path<T>;
  id: string;
  label: string;
  placeholder?: string;
  options?: RegisterOptions;
  disabled?: boolean;
  className?: string;
  tooltipContent?: string;
}

export function GenericFormField<T extends FieldValues>({
  field,
  id,
  label,
  placeholder,
  options,
  disabled,
  className,
  tooltipContent,
}: GenericFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();

  const fieldError = get(errors, field)?.message as string | undefined;

  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div className={`flex flex-col pt-4 space-y-1 ${className}`}>
          <TextInput
            className={disabled ? 'text-neutral-60' : 'text-black'}
            id={id}
            name={name}
            label={label}
            value={value}
            disabled={disabled}
            placeholder={placeholder || 'Digite aqui'}
            required={(options?.required as boolean) || false}
            error={fieldError}
            onBlur={onBlur}
            onChange={onChange}
            tooltipContent={tooltipContent}
          />
        </div>
      )}
    />
  );
}
