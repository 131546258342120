import React from 'react';
import { TextInput } from '@clarke-energia/foton';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';

export interface FormFieldProps<T extends FieldValues> {
  field: Path<T>;
  id: string;
  label: string;
  placeholder?: string;
  options?: RegisterOptions;
  disabled?: boolean;
}

export function FormField<T extends FieldValues>({
  field,
  id,
  label,
  placeholder,
  options,
  disabled,
}: FormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();
  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <TextInput
          className={disabled ? 'text-neutral-60' : 'text-black'}
          id={id}
          name={name}
          label={label}
          value={value}
          disabled={disabled}
          placeholder={placeholder || 'Digite aqui'}
          required={(options?.required as boolean) || false}
          error={errors[field]?.message as string | undefined}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
    />
  );
}
