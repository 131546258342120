import React from 'react';
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { DateInput } from '@clarke-energia/foton';

export interface DateFormFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  label: string;
  placeholder?: string;
  id: string;
  options?: RegisterOptions;
}

export function DateFormField<T extends FieldValues>({
  field,
  label,
  id,
  placeholder,
  options,
}: DateFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();
  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div className="flex flex-col space-y-1">
          <DateInput
            id={id}
            name={name}
            label={label}
            value={value}
            placeholder={placeholder || 'DD/MM/YYYY'}
            required={(options?.required as boolean) || false}
            error={errors[field]?.message as string | undefined}
            onBlur={onBlur}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>
      )}
    />
  );
}
