import React from 'react';
import { useFormContext } from 'react-hook-form';

import { GuaranteeTypeEnum } from '@utils/translators/proposal';
import { CreateEnergyContractPayload, PayDayType } from '@contexts/energy-contract/types';

import {
  SelectFormField,
  NumericFormField,
  FormSection,
  FormField,
  GroupSelectInputFormField,
} from '@components/atoms/form';

const FormFinancialConditionsSection = () => {
  const { watch, setValue } = useFormContext();
  const guaranteeType = watch('guaranteeType');
  const payDayType = watch('payDayType');

  const displayGuaranteeMonths =
    guaranteeType === 'OTHER' || guaranteeType === 'REGISTRATION_AGAINST_PAYMENT' || guaranteeType === 'NO_GUARANTEE';

  const payDayTypeOptions = [
    { label: 'Dias úteis', value: PayDayType.WORKDAY },
    { label: 'Dias corridos', value: PayDayType.CALENDAR_DAY },
  ];

  return (
    <div className="gap-6 mb-7 lg:grid lg:grid-cols-3 xl:grid-cols-4 xl:mb-10">
      <FormSection title="Condições financeiras" subtitle="Dados sobre pagamentos, garantia." />
      <div className="gap-x-6 gap-y-6 lg:grid lg:grid-cols-2 lg:col-span-2 xl:grid-cols-3 xl:col-span-3">
        <GroupSelectInputFormField<CreateEnergyContractPayload>
          firstField="payDay"
          secondField="payDayType"
          id="payDay"
          selectOptions={payDayTypeOptions}
          label="Data de pagamento"
          type="number"
          formatProps={{
            placeholder: payDayType === PayDayType.WORKDAY ? '5º dia útil' : '7º dia corrido',
            suffix: 'º',
            allowNegative: true,
            fixedDecimalScale: false,
            decimalScale: 0,
          }}
          options={{
            required: {
              value: true,
              message: 'Dia e tipo do pagamento é obrigatório',
            },
            validate: (value) => {
              const isNumberValue = typeof value === 'number';
              const valueStr = isNumberValue ? value.toString() : value;

              const payDayLength = valueStr.length > 2;
              if (isNumberValue && payDayLength) {
                return 'A data de pagamento deve conter 2 dígitos.';
              }
              if (valueStr === '0') {
                return 'A data de pagamento deve ser diferente de zero.';
              }
            },
            onChange: (e) => {
              const value = e.target.value;
              if (typeof value !== 'number') {
                setValue('payDayType', value);
                setValue('payDay', null);
              }
            },
          }}
        />

        <SelectFormField<CreateEnergyContractPayload>
          label="Tipo de garantia"
          field="guaranteeType"
          id="guaranteeType"
          inputOptions={Object.keys(GuaranteeTypeEnum).map((key) => ({
            value: key,
            optionLabel: GuaranteeTypeEnum[key as keyof typeof GuaranteeTypeEnum],
          }))}
          options={{ required: { value: true, message: 'Tipo de garantia é obrigatório' } }}
        />

        {guaranteeType && !displayGuaranteeMonths ? (
          <NumericFormField<CreateEnergyContractPayload>
            field="guaranteeMonths"
            id="guaranteeMonths"
            label="Meses de faturamento"
            formatProps={{
              placeholder: 'Meses',
              suffix: ' meses',
              allowNegative: false,
              fixedDecimalScale: false,
              decimalScale: 0,
            }}
            options={{
              required: {
                value: guaranteeType && !displayGuaranteeMonths ? true : false,
                message: 'Meses de faturamento é obrigatório',
              },
            }}
          />
        ) : null}
        {guaranteeType === 'OTHER' ? (
          <FormField<CreateEnergyContractPayload>
            field="otherGuaranteeType"
            id="otherGuaranteeType"
            label="Informe outra garantia"
            options={{
              required: {
                value: guaranteeType === 'OTHER' ? true : false,
                message: 'Outro tipo de garantia é obrigatório',
              },
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FormFinancialConditionsSection;
