import { State } from '@hooks/edcs';
import { ContractTypeEnum } from '@utils/translators/proposal';

import { DevecObejectKey } from './parser';
import { TraderTypeEnum } from '@contexts/trader';

interface FreeMarketFormData {
  readDemandOffPeak: number;
  readDemandPeak: number | null;
  energyConsumptionPeak: number | null;
  energyConsumptionOffPeak: number | null;
  reactiveDemandOffPeak: number | null;
  reactiveDemandPeak: number | null;
  reactiveEnergyOffPeak: number | null;
  reactiveEnergyPeak: number | null;
  reactiveEnergyCost: number | null;
  tusdDiscount: number;
  delays: number | null;
  pisCofins: number | null;
  othersCosts: number;
  energyProvidedInput: number;
  distributionAdjustmentValue: number | null;
  loadFactor: number | null;
  energyDistribution: { covidTariffCost: number | null };
  energyProvided: { energyProvidedCost: number; energyProvidedAdjustmentValue: number };
  others: {
    clarkeManagementCost: number;
    cceeCost: number | null;
    eerCost: number | null;
    essCost: number | null;
  };
}

interface ConventionalMarketFormData {
  others: number | null;
  tariffFlagCost: number | null;
}

export enum IReportStatus {
  DRAFT = 'Rascunho',
  FINAL = 'Publicado',
}

export interface IHandleUpdateReportsStatus {
  status: keyof typeof IReportStatus;
  reports: Array<{
    reportId: string;
    reportType: 'GROUP' | 'UNIT';
  }>;
}

export interface UnitReport {
  id: string;
  date: string;
  currentSavings: number;
  savingsPercent: number;
  trader: { name: string; id: string };
  groupId: string;
  unit: {
    name: string;
    id: string;
    edc: { name: string };
    contractedDemandOffPeak: number;
  };
  energyTotalConsumption: number;
  energyTotalCost: number;
  freeMarket: FreeMarketFormData;
  conventionalMarket: ConventionalMarketFormData;
  devecDeclaration: boolean | null;
  status: keyof typeof IReportStatus;
  createdAt: string;
}

export interface GroupEconomyReport {
  id: string;
  date: string;
  totalMonthlySavings: number;
  totalMonthlySavingsPercent: number;
  totalMonthlyEnergyCost?: number;
  totalMonthlyEnergyConsumption?: number;
  economyReports: UnitReport[];
  group: {
    id: string;
    name: string;
  };
  status: keyof typeof IReportStatus;
}

export interface GroupEconomyReports {
  id: string;
  date: string;
  totalMonthlySavings: number;
  totalMonthlySavingsPercent: number;
  totalMonthlyEnergyCost?: number;
  totalMonthlyEnergyConsumption?: number;
  group: {
    id: string;
    name: string;
  };
  status: keyof typeof IReportStatus;
  createdAt: string;
}

export interface EconomyReport extends UnitReport {
  groupReport: GroupEconomyReports;
}

export interface ReportsDataTable {
  id: string;
  date: string;
  groupName: string;
  unitName: string;
  economy: string;
  formDataToUpdate: EconomyReportPayload | GroupEconomyReportPayload;
  status: keyof typeof IReportStatus;
  createdAt: string;
}

export interface GroupReport {
  name: string;
  date: string;
  contractType: string;
  traders: string;
  edcs: string;
  totalMonthlySavings: string;
  totalMonthlySavingsPercent: string;
  totalMonthlyEnergyConsumption: string;
  totalMonthlyEnergyCost: string;
  unitsAmount: number;
  contractedDemandAmount: string;
  unitsTableData: UnitsReportsTableData[];
}

export interface UnitsReportsTableData {
  unitReportLink: string;
  unit: string;
  saved: string;
  energyCost: string;
  energyConsumption: string;
}

export interface UnitFormReportData {
  id: string;
  name: string;
  docNumber: string;
  tariffModality: Tariff;
  tariffSubgroup: Subgroup;
  contractedDemandOffPeak: number;
  contractedDemandPeak: number;
  contractedDemandOffPeakBeforeMigration: number;
  contractedDemandPeakBeforeMigration?: number;
  unitType: 'COMMERCIAL' | 'RURAL' | 'INDUSTRIAL';
  edc: {
    id: string;
    state: State;
    taxes: {
      percentageValue: number;
      taxType: string;
    }[];
  };
  clarkeContract?: {
    clarkeMagmentAmount: number;
  };
  energyContract?: {
    trader: {
      id: string;
      name: string;
      type: keyof typeof TraderTypeEnum;
    };
    contractType: keyof typeof ContractTypeEnum;
    includeCceeCharges: boolean;
    economy: {
      year: number;
      amount: number;
    }[];
  };
}

export interface EconomyPerYear {
  amount: number;
  year: number;
}
export interface Bid {
  retailService: boolean;
  contractType: 'GUARANTEED_SAVING' | 'FIXED_PRICE';
  winnerAt: string;
  economy: {
    economyPerYear: EconomyPerYear[];
  }[];
}

export interface GroupData {
  id: string;
  name: string;
  units: UnitFormReportData[];
}

export interface UnitFormData {
  name: string;
  docNumber: string;
  id: string;
  tariffType: TariffModality;
  edcId: string | null;
  aclIcmsDistribution: number;
  aclIcmsSupply: number;
  aclContractedDemandOffPeak: number;
  aclContractedDemandPeak: number;
  acrContractedDemandPeak: number;
  acrContractedDemandOffPeak: number;
  devecState: DevecObejectKey | null;
  retailService: boolean | null;
  aclEnergyProvidedCost: number | null;
  aclContractType: keyof typeof ContractTypeEnum | null;
  clarkeManagementCost: number | null;
  traderId: string;
  displayChargesForRetail: boolean | undefined;
}

export interface GroupFormData {
  name: string;
  groupId: string;
  units: UnitFormData[];
}

export enum DevecStatesType {
  GO,
  MA,
  MT,
  PR,
  PE,
  RJ,
  RO,
  SC,
  SE,
}

export type Tariff = 'GREEN' | 'BLUE';
export type Subgroup = 'A3' | 'A4';

export enum TariffModality {
  A4_BLUE = 3,
  A4_GREEN = 2,
  A3_BLUE = 6,
}

export interface EconomyReportPayload {
  id?: string;
  groupId: string;
  unitId: string;
  date: string | null;
  traderId: string;
  edcId: string | null;
  tariffType: TariffModality | null;
  aclContractType: keyof typeof ContractTypeEnum | null;
  devecDeclaration?: boolean | null;
  aclReadDemandOffPeak: number | null;
  aclReadDemandPeak?: number | null;
  acrTariffFlagCost: number | null;
  acrOthersCosts: number | null;
  clarkeManagementCost: number | null;
  aclEnergyProvided: number | null;
  aclEnergyConsumptionPeak: number | null;
  aclEnergyConsumptionOffPeak: number | null;
  aclReactiveDemandOffPeak: number | null;
  aclReactiveEnergyPeak: number | null;
  aclReactiveEnergyOffPeak: number | null;
  aclTusdDiscount: number | null;
  aclReactiveEnergyCost: number | null;
  aclOthersCosts: number | null;
  aclDelays?: number | null;
  aclPisCofins: number | null;
  aclDistributionAdjustmentValue?: number | null;
  aclEnergyProvidedCost?: number | null;
  aclReactiveDemandPeak?: number | null;
  aclEerCost?: number | null;
  aclEssCost?: number | null;
  aclCceeCost?: number | null;
  aclCovidCost?: number | null;
  devecState?: keyof typeof DevecStatesType | null;
  aclEnergyProvidedAdjustmentValue?: number | null;
  aclIcmsDistribution: number | null;
  aclIcmsSupply: number | null;
  aclContractedDemandOffPeak: number | null;
  aclContractedDemandPeak?: number | null;
  aclLoadFactor?: number | null;
  acrContractedDemandPeak?: number | null;
  acrContractedDemandOffPeak: number | null;
}

export const EMPTY_REPORT: EconomyReportPayload = {
  groupId: '',
  date: null,
  unitId: '',
  traderId: '',
  edcId: '',
  aclContractType: null,
  acrTariffFlagCost: null,
  acrOthersCosts: null,
  clarkeManagementCost: null,
  aclEnergyProvided: null,
  aclReadDemandOffPeak: null,
  aclReadDemandPeak: null,
  aclEnergyConsumptionPeak: null,
  aclEnergyConsumptionOffPeak: null,
  aclReactiveDemandOffPeak: null,
  aclReactiveEnergyPeak: null,
  aclReactiveEnergyOffPeak: null,
  aclTusdDiscount: null,
  aclOthersCosts: null,
  aclDelays: null,
  aclPisCofins: null,
  aclDistributionAdjustmentValue: null,
  aclEnergyProvidedAdjustmentValue: null,
  aclReactiveEnergyCost: null,
  aclReactiveDemandPeak: null,
  aclEnergyProvidedCost: null,
  aclCovidCost: null,
  aclEerCost: null,
  aclEssCost: null,
  aclCceeCost: null,
  devecState: null,
  devecDeclaration: null,
  tariffType: null,
  aclIcmsDistribution: null,
  aclIcmsSupply: null,
  aclContractedDemandOffPeak: null,
  aclContractedDemandPeak: null,
  aclLoadFactor: null,
  acrContractedDemandPeak: null,
  acrContractedDemandOffPeak: null,
};

export interface GroupEconomyReportPayload {
  id?: string;
  groupId: string;
  date: string;
}

export const EMPTY_GROUP_REPORT: GroupEconomyReportPayload = {
  groupId: '',
  date: '',
};

interface GroupInput {
  name: string;
  emails: Array<string>;
}

export interface NotifyReportInput {
  group: GroupInput;
  reportId: string;
}

interface ClarkeContract {
  clarkeMagmentAmount: number;
}

interface EnergyContractEconomyReport {
  trader?: {
    id: string;
    name: string;
  };
}

export interface UnitConsumptionAndFinancialAmounts {
  id: string;
  consumptionAmount: number;
  eerAmount?: number;
  liquidationAmount?: number;
  cceeAmount?: number;
}

export interface FinancialEventsInput {
  units: UnitConsumption[];
  totalConsumption: number;
  totalLiquidationFee: number;
  totalCceeFee: number;
  totalEerFeeByProfile: Record<string, number>;
}

export interface UnitConsumption {
  id: string;
  consumptionAmount: number;
  profileName: string | null;
}

export interface FinancialEvent {
  eerFeePerProfiles?: Record<string, number>;
  amountToPay?: number;
  liquidationFee?: number;
}

interface EnergyAuditEconomyReport {
  consumptionTotal: number;
  units: {
    unit: {
      id: string;
      cceeProfile: {
        name: string;
      };
    };
    consumptionAmount: number;
  }[];
}

export interface SupplyInvoice {
  supplyAmountMwh: number | null;
  price: number | null;
}

export interface ClarkeContractGraphqlResponse {
  getClarkeContractByGroupId: ClarkeContract;
}

export interface EnergyContractGraphqlResponse {
  energyContractsByGroupId: EnergyContractEconomyReport[];
}

export interface EnergyAuditGraphqlResponse {
  getAllEnergyAudits: { data: EnergyAuditEconomyReport[] };
}

export interface SupplyInvoiceGraphqlResponse {
  getSupplyInvoiceByDocNumberAndDate: SupplyInvoice;
}

export interface FinancialEventGraphqlResponse {
  getFinancialEvents: { data: FinancialEvent[] };
}

export interface GroupGraphqlResponse {
  group: GroupData;
}
